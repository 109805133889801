<template>
  <div>
    <Breadcrumbs title="Mark Attendance" />
    <div class="container-fluid">

      <div class="card">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-8 col-lg-10">
              <b-form-group label="Student NIC *">
                <nic_field size="lg" placeholder="Search student by NIC" :nic="nic" v-on:updateNic="assignNIC" />
              </b-form-group>
            </div>
            <div class="col-md-2 col-lg-2">
              <b-button class="btn btn-block btn-square btn-primary btn-lg m-t-30" @click="clearForm" :disabled="nic===null">CLEAR</b-button>
            </div>
          </div>
        </div>
      </div>


      <b-row v-if="student != null">
        <b-col cols="12" lg="4" v-for="(schedule, index) in tableRecords" :key="index">
          <div class="card" @click="submitMarkAttendance(schedule)">
            <div class="card-header bg-dark text-white">
              <h4>{{schedule.class_detail.name}}</h4>
              <h5 class="mt-1">{{schedule.formatted_start_time}} - {{schedule.formatted_end_time}}</h5>
              <h5 class="mt-2">{{schedule.day}}</h5>
            </div>
            <div class="card-body" v-if="false">
              <b-row>
                <b-col class="border-1">
                  <h5 class="card-title m-y-0 p-y-0">Barriers</h5>
                  <template v-if="schedule.barriers.length">
                    <b-row v-for="(barrier, index) in schedule.barriers" :key="index">
                        <b-col cols="12">{{toFullDate(barrier.start_at)}} - {{toFullDate(barrier.end_at)}}</b-col>
                        <b-col cols="12" v-show="barrier.remark">{{ barrier.remark }}</b-col>
                      </b-row>
                    <br>
                  </template>
                  <template v-else>
                    <b-row>
                      <b-col cols="12">N/A</b-col>
                    </b-row>
                  </template>
                  <h5 class="card-title m-y-0 p-y-0">Payments</h5>
                  <template v-if="schedule.payments.length">
                    <b-row v-for="(payment, index) in schedule.payments" :key="index">
                      <b-col>{{ getPaymentMonth(payment.payment_month) }}</b-col>
                      <b-col v-if="payment.free_card_type === 2"><span class="badge badge-warning">Half Card</span></b-col>
                      <b-col v-else-if="payment.free_card_type === 1"><span class="badge badge-danger">Free Card</span></b-col>
                      <b-col v-else>Rs. {{ payment.amount }}</b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <b-row>
                      <b-col cols="12">N/A</b-col>
                    </b-row>
                  </template>

                  <br>
                  <h5 class="card-title">Free Cards</h5>
                  <template v-if="schedule.free_cards.length">
                    <b-row v-for="(fcard, index) in schedule.free_cards" :key="index">
                      <b-col cols="12">
                        {{ getPaymentMonth(fcard.start_at) }}
                      </b-col>
                      <b-col cols="12" v-if="fcard.remark.length">
                        <sup>{{ fcard.remark }}</sup>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <b-row>
                      <b-col cols="12">N/A</b-col>
                    </b-row>
                  </template>
                </b-col>
                <b-col>
                  <h5 class="card-title m-y-0 p-y-0">Attendance</h5>
                  <template v-if="schedule.attendance.length">
                    <b-row v-for="(attendance, index) in schedule.attendance" :key="index">
                      <b-col>
                        {{ toDate(attendance.created_at) }}
                      </b-col>
                      <b-col>
                        Seat {{ attendance.seat_number }}
                      </b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <b-row>
                      <b-col cols="12">N/A</b-col>
                    </b-row>
                  </template>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>





      <div class="card" v-if="student != null" v-show="false">
        <div class="card-body">

          <div class="form-row">
            <div class="col-12">
              <div class="table-responsive">
                <b-table
                    ref="marker_table"
                    show-empty
                    selectable
                    :items="tableRecords"
                    :fields="tableFields"
                    hover
                >
                  <template #cell(payments)="row">
                    <b-row v-for="(payment, index) in row.item.payments" :key="index">
                      <b-col>
                        {{ getPaymentMonth(payment.payment_month) }}
                      </b-col>
                      <b-col>
                        Rs. {{ payment.amount }}
                      </b-col>
                    </b-row>
                  </template>
                  <template #cell(attendances)="row">
                    <b-row v-for="(attendance, index) in row.item.attendance" :key="index">
                      <b-col>
                        {{ toDate(attendance.created_at) }}
                      </b-col>
                      <b-col>
                        Seat {{ attendance.seat_number }}
                      </b-col>
                    </b-row>
                  </template>
                  <template #cell(action)="row">
                    <b-button @click.prevent="submitMarkAttendance(row.item)" :disabled="!student" class="btn btn-block btn-square btn-primary btn-lg">Mark</b-button>
                  </template>
                </b-table>


              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import moment from "moment";
import nic_field from "@/components/nic_field";
export default {
  name: "mark_attendance",
  components: { nic_field },
  created() {
    this.$store.dispatch('auth/getUser')
  },
  data(){
    return {
      nic: '',
      is_searching: false,
      student: null,
      class_detail_id: null,
      tableRecords: [],
      tableFields: [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'day', label: 'Day / Date', sortable: true },
        { key: 'class_detail.name', label: 'Class', sortable: true },
        { key: 'payments', label: 'Payments (Date/Amount)', sortable: false, },
        { key: 'attendances', label: 'Attendances (Date/Seat)', sortable: false, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],
    }
  },
  computed: {
    student_name() {
      if(this.student) {
        return this.student.first_name + " " + this.student.last_name + " ( "+this.student.nic+" )";
      } return "";
    },
  },
  methods: {
    toDate(val) {
      return moment(val).format('MMM - DD')
    },
    toFullDate(val) {
      return moment(val).format('YYYY-MMM-DD')
    },
    getPaymentMonth(val){
      return moment(val).format('YYYY - MMM')
    },
    clearForm(){
      this.nic = ''
      this.student = null
      this.class_detail_id = null
    },
    assignNIC(value){
      this.nic = value
      this.searchStudent()
    },
    async searchStudent() {
      this.tableRecords = []
      if(this.nic && !this.is_searching) {
        this.is_searching = true
        // Prepare Barcode Reader Input
        // if (this.nic.length > 12) {
        //   let parts = this.nic.split('/');
        //   this.nic = parts[0];
        //   this.nic = this.nic.substring(2, -4);
        // }

        console.log('scanned_nic', this.nic)

        this.student = null
        await API.student_by_nic('/api/backend', {
          nic: this.nic,
          institute_id: this.institute_id,
          with_relations: ['roles:id,name','institutes:id,name']
        }).then((response) => {
          if(response.data.success) {
            this.student = response.data.data
          } else {
            this.$swal({
              html: `<h2 class="display-4">`+response.data.message+`</h2>`,
              showConfirmButton: false,
              showCancelButton: false,
              allowEnterKey: true,
              allowEscapeKey: true,
              type: 'error',
              // background: '#ff9f0a',
              grow: 'fullscreen',
              timer: 1000
            }).then(() => {
              this.clearForm()
            });
          }
        }).catch((error) => {
          console.log(error)
          this.$swal({
            html: `<h2 class="text-white display-4">`+JSON.stringify(error.message)+`</h2>`,
            showConfirmButton: false,
            showCancelButton: false,
            allowEnterKey: true,
            allowEscapeKey: true,
            type: 'error',
            background: '#ff9f0a',
            grow: 'fullscreen',
            timer: 1000
          }).then(() => {
            this.clearForm()
          });
        })
        .finally(() => {
          this.is_searching = false
        })

        if(this.student) {
          if (this.student.status === 0) {
            await API.post('/api/backend/get-attendance-classes', {
              student_id: this.student.id
            }).then((result) => {
              this.tableRecords = result.data.data
              if(!this.tableRecords.length) {
                this.$swal({
                  html: `<h2 class="text-white display-4">`+this.student_name+`<br><br>No Schedules Found</h2>`,
                  showConfirmButton: false,
                  showCancelButton: false,
                  allowEnterKey: true,
                  allowEscapeKey: true,
                  type: 'error',
                  background: '#FF3D57',
                  grow: 'fullscreen',
                  timer: 1000
                }).then(() => {
                  this.clearForm()
                });
              } else if (1===this.tableRecords.length) {
                this.submitMarkAttendance(this.tableRecords[0]);
              }
            }).catch((errors) => {
              console.log(errors)
            }).finally(() => {
              this.$refs.marker_table.refresh()
            })
          } else {
            this.$swal({
              html: `<h2 class="text-white display-4">`+this.student_name+`</h2>`
                  + `<h2 class="text-white display-4">Inactive Student Account</h2>`,
              showConfirmButton: false,
              showCancelButton: false,
              allowEnterKey: true,
              allowEscapeKey: true,
              type: "info",
              background: '#242939',
              grow: 'fullscreen',
              timer: 1000
            }).then(() => {
              this.clearForm()
            });
          }
        }
      }
    },
    async submitMarkAttendance(item) {
      await API.post('/api/backend/set-attendance', {
        class_id: item.class_detail_id || 0,
        student_id: this.student.id,
        class_schedule_id: item.id
      }).then((result) => {
        this.clearForm()

        this.$swal({
          html: `<h2 class="text-white display-4">`+result.data.message+`</h2><br><h1 class="text-white display-1">` + result.data.title + `</h1>`,
          showConfirmButton: false,
          showCancelButton: false,
          allowEnterKey: true,
          allowEscapeKey: true,
          type: result.data.data.type,
          background: result.data.data.color,
          grow: 'fullscreen',
          timer: 1000,
        });

      }).catch((error) => {
        console.log(error)
        this.$swal({
          html: `<h2 class="text-white display-4">`+error.message+`</h2>`,
          showConfirmButton: false,
          showCancelButton: false,
          allowEnterKey: true,
          allowEscapeKey: true,
          type: 'error',
          background: '#FF8A48',
          grow: 'fullscreen',
          timer: 1000
        });
      }).finally(() => {
        // this.$refs.nic.$el.focus()
        // this.$refs.nic.focus()
      })
    }
  }
}
</script>

<style scoped>

</style>
